import axios from "axios";
import { find, findAll } from "../utils/dom";


const modal_login = () => {
    const BASE_URL = `${window.location.protocol}//${window.location.host}`;

    /** Form modal login mobile */
    function init_modal_login_mobile() {
        const modal_login_mobile = findAll(".form-login-meg");

        if (!modal_login_mobile) return;

        modal_login_mobile.forEach((item) => {
            item.addEventListener("submit", (e) => {
                e.preventDefault();

                const formDados = new FormData(e.target);

                const username = formDados.get('username');
                const password = formDados.get('password');

                const messageError = e.target.querySelector("[js-error-message]");


                axios({
                    method: 'post',
                    data: formDados,
                    url: `${BASE_URL}/login-inscricao/`
                })
                    .then((result) => {
                        if (result.status == 200) {
                            window.location.href = `${BASE_URL}/admin/`
                        }
                    })
                    .catch((error) => {
                        messageError.innerText = error.response.data.message;
                    })
            })
        })

    }



    function closed_modal_desktop(modal) {
        const modalDesktop = find(`[js-modal='${modal}']`);
        if (!modalDesktop) return;
        modalDesktop.removeAttribute("open");
    }

    function open_modal_desktop(modal) {
        const modalDesktop = find(`[js-modal='${modal}']`);
        if (!modalDesktop) return;
        modalDesktop.setAttribute("open", "");
    }

    function init_modal_desktop(modal) {
        const modalDesktop = find(`[js-modal='${modal}']`);
        const contentModalDesktop = modalDesktop.querySelector("[js-content-modal]");
        if (!modalDesktop && !contentModalDesktop) return;


        modalDesktop.addEventListener("click", (e) => {
            if (!contentModalDesktop.contains(e.target)) {
                closed_modal_desktop(modal);
            }
        })

        const btOpen = findAll(`[js-modal-open='${modal}']`);
        btOpen?.forEach((bt) => {
            bt.addEventListener("click", (e) => {
                open_modal_desktop(modal);
            })
        })

        const btClose = findAll(`[js-modal-closed='${modal}']`);
        btClose?.forEach((bt) => {
            bt.addEventListener("click", (e) => {
                closed_modal_desktop(modal);
            })
        })


    }



    /** Modal Desktop */
    init_modal_desktop('login-desktop');

    /** Modal Mobile */
    init_modal_desktop('login-mobile-login');
    init_modal_desktop('login-mobile-inscricao');

    /** Veirificar o modal Login depois */
    init_modal_login_mobile();
}

export default modal_login;