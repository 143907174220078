
import { find } from "utils/dom"

export default function () {
	const Modal = find(`[js-modal="modal-default"]`);
	if (!Modal) return

	const eyeEffectLoginMeg = () => {
		const eyeOn = document.querySelector(".eye-on");
		const eyeOff = document.querySelector(".eye-off");
		const buttonEye = document.querySelector(".btn-eye");
		const inputValue = document.querySelector(".input-password-meg");

		buttonEye?.addEventListener("click", () => {
			if (inputValue.type === "password") inputValue.type = "text";
			else inputValue.type = "password";
			eyeOn?.classList.toggle("hidden");
			eyeOff?.classList.toggle("hidden");
		});
	}

	eyeEffectLoginMeg();

}