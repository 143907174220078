import { find, findAll } from "../utils/dom";
export default function dragAndMove(){

  
    const page = find("[js-page='single_torneio_playoff']");
        if (!page) return;


			
					const container = document.querySelector('[js-resultado-modalidades]');
			
					// Function to initialize drag-to-scroll functionality
					function initializeDragScroll(selectors) {
							selectors.forEach(selector => {
									const sliders = document.querySelectorAll(selector);
			
									sliders.forEach(slider => {
											let mouseDown = false;
											let startX, scrollLeft;
			
											const startDragging = (e) => {
													mouseDown = true;
													startX = e.pageX - slider.offsetLeft;
													scrollLeft = slider.scrollLeft;
			
													// Mudar o cursor para "grabbing" ao clicar
													slider.style.cursor = 'grabbing';
											};
			
											const stopDragging = (e) => {
													mouseDown = false;
			
													// Voltar o cursor ao normal (grab) quando soltar o botão do mouse
													slider.style.cursor = 'grab';
											};
			
											const move = (e) => {
													e.preventDefault();
													if (!mouseDown) { return; }
													const x = e.pageX - slider.offsetLeft;
													const scroll = x - startX;
			
													// Inverte a direção do scroll ao usar scaleX(-1)
													slider.scrollLeft = scrollLeft + scroll;
											};
			
											// Inicialmente, o cursor deve ser "grab" quando hover
											slider.style.cursor = 'grab';
			
											// Add the event listeners
											slider.addEventListener('mousemove', move, false);
											slider.addEventListener('mousedown', startDragging, false);
											slider.addEventListener('mouseup', stopDragging, false);
											slider.addEventListener('mouseleave', stopDragging, false);
									});
							});
					}
			
					
							// Pass an array of selectors for the elements you want to initialize
							initializeDragScroll(['.partidas-wrapper']);
  
          

}