import axios from 'axios'

import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export default function Newsletter(){

    const contatoFormSubmit = document.querySelector('#newsletter-form')


    contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function(event) {
        event.preventDefault();
        disabledBtn()
        const form_data = {
            email: document.querySelector('#newsletter-form input[name=email]').value
        }
        console.log(form_data);
        const url = "/newsletter/"
        axios.post(url, form_data)
            .then((response) => showMessage(response))
            .catch((response) => showMessage(response));
    });

    function showMessage(response) {
        const message = response.data ? response.data.message : response.message
        Toastify({
            text: message,
            duration: 3000,
            position: "center",
            style: { background: "#1A1A1A" },
        }).showToast();
        contatoFormSubmit.reset()
        setTimeout(enabledBtn, 1000)
    }

    const btnSubmit = document.querySelector("#newsletter-form button[type=submit]")

    function disabledBtn() {
        btnSubmit.disabled = true
        btnSubmit.classList.add("disabled")
    }

    function enabledBtn() {
        btnSubmit.disabled = false
        btnSubmit.classList.remove("disabled")
    }
}   
