import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Footer = find("[js-page='footer']")
	if (!Footer) return

	const subMenu = document.querySelectorAll(".submenu li")
    subMenu.forEach(i=>{
        i.addEventListener("click", (e) =>{
            const menu = document.querySelector('.menu-mobile')
            menu.style.display = 'none'
            console.log("Itens SubMenu",  e.target.innerText);
        })
    })
}