import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return



	/** Swiper Inovação | Comunicação | Crescimento */
	const SwiperModalidade = new Swiper(".swiper-modalidades", {
		slidesPerView: 1.2,
		spaceBetween: 15,
		speed: 1000,
		loop: true,
		autoplay: {
			delay: 3000,
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		breakpoints: {
			600: {
				slidesPerView: 2.5,
			},
			800: {
				slidesPerView: 3.5,
			},
			1200: {
				slidesPerView: 5
			}
		}


	});

	const SwiperInovacao = new Swiper(".SwiperInovacao", {
		slidesPerView: 2.5,
		spaceBetween: 0,
		direction: "vertical",
		centeredSlides: true,
		speed: 700,
		loop: true,
		autoplay: {
			delay: 3000,
		}

	});

	const SwiperComunicacao = new Swiper(".SwiperComunicacao", {
		slidesPerView: "auto",
		spaceBetween: 0,
		direction: "vertical",
		centeredSlides: true,
		speed: 700,
		loop: true,
		autoplay: {
			delay: 3000,
		}

	});

}