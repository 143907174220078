import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import PageNoticias from './pages/Noticias';
import PageSingleNoticias from "./pages/Single_Noticias";
import PageResultados from "./pages/Resultados";
import PageResultados2 from "./pages/Resultados_2";
import PageCadastro from './pages/Cadastro';
import PageResultados2New from "./pages/Resultados_2_new";
import PageTimes from './pages/Time';
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import PageFooter from './pages/footer.js'
import loginMeg from './pages/LoginMEG'

import modal_login from './modules/moda_login';

// import SvgUse from "./utils/SvgUse";

import EditorJS from './modules/Editor';
import Newsletter from './modules/newsletter';
import newEditorJs from './modules/newEditor';
import dragAndMove from './modules/dragAndMove';

import mostrarSenha2 from './modules/mostrarSenha2';
import PageTransmissao from './pages/Transmissao';

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()

// Pages
PageHome();
PageFooter();
PageSingleNoticias();
PageNoticias();
PageResultados();
PageResultados2New();
PageCadastro();
PageTransmissao();

//PageResultados2()

PageTimes()
PageLogin()?.init()

Newsletter();


newEditorJs();
EditorJS();
// dragAndMove();

loginMeg()

// utils logs
logs()

//Modulos
modal_login();
mostrarSenha2();


