import { find, findAll } from "../utils/dom";
import Swiper from "swiper";


function check_label_ao_vivo() {
    const is_aovivo = find('[js-ao-vivo]');
    if (!is_aovivo) {
        const content_aovivo = find('[js-content-ao-vivo]');
        content_aovivo.style.display = 'none';
    }
}

const PageTransmissao = () => {
    const page = find(`[js-page='transmissao']`);
    if (!page) return;

    let swiperTransmissao = new Swiper(".transmissao", {
        slidesPerView: 3.5,
        spaceBetween: 30,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });

    check_label_ao_vivo();
}




export default PageTransmissao;