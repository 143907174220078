import { find, findAll } from "../utils/dom"



export const active_label_passo = (label_passo) => {
    label_passo.classList.add('active');
}

export const get_label_passo = (label_passo) => {
    return find(`[js-bar-footstep='${label_passo}']`);
}





export const load_passos = () => {
    const passos = findAll('[js-footstep]');
    return passos;
}

export const get_passo = (passo) => {
    const result = passo.getAttribute('js-footstep');
    return result;
}

export const active_passo = (passo) => {
    passo.classList.add('active');
    const label = get_label_passo(get_passo(passo));
    if (label) {
        active_label_passo(label);
    }
}

export const reset_passos = () => {
    const passos = load_passos();
    passos?.forEach((single_passo) => {
        single_passo.classList.remove('active');
    })

    const labels = findAll('[js-bar-footstep]');
    labels?.forEach((single_label) => {
        single_label.classList.remove('active');
    })
}

export const update_passo = (prox_passo) => {
    reset_passos();
    for (let i = 1; i <= parseInt(prox_passo); i++) {
        const atual_passo = find(`[js-footstep='${i}']`);
        atual_passo.classList.add('active');

        const label = get_label_passo(i);
        if (label)
            label.classList.add('active');
    }
}
