import { find, findAll } from "../utils/dom";


const mostrarSenha2 = () => {


    function trocarTypeInput(input, outlineOn, outlineOff) {
        if (input.type === 'password') {
            input.type = 'text';
            outlineOn?.classList.remove('hidden');
            outlineOff?.classList.add('hidden');
        }
        else {
            input.type = 'password';
            outlineOn?.classList.add('hidden');
            outlineOff?.classList.remove('hidden');
        }
    }

    function handleInput(input_password, outlineOn, outlineOff) {
        input_password?.forEach((input) => {
            trocarTypeInput(input, outlineOn, outlineOff);
        })
    }

    function init_mostrarSenha2(field_name) {
        const fieldDiv = find(`[js-field-password='${field_name}']`);
        if (!fieldDiv) return;

        const button = fieldDiv.querySelectorAll(`[js-button-password]`);
        const password = fieldDiv.querySelectorAll(`input[type='password']`);
        const outlineOn = fieldDiv.querySelector('.eye-on');
        const outlineOff = fieldDiv.querySelector('.eye-off');

        button?.forEach((single_bt) => {
            single_bt.addEventListener("click", () => {
                handleInput(password, outlineOn, outlineOff);
            })
        })
    }


    init_mostrarSenha2('login');
}

export default mostrarSenha2;